.container {
    height: 100%;
    align-content: center;
    width: 100%;
}

.content-center {
    align-content: center;
    width: 100%;
}

.nav-background {
    background-color: #FFC312;
}

.social_icon span {
    font-size: 60px;
    margin-left: 10px;
    color: #FFCC00;
}

.social_icon span:hover {
    color: white;
    cursor: pointer;
}

.card-header h3 {
    color: white;
}

.social_icon {
    position: absolute;
    right: 20px;
    top: -45px;
}

.input-group-prepend span {

    background-color: #eeeeee;
    color: black;
    border: 0 !important;
}

.space {
    margin: 10px;
}

.login-btn:hover, .search-btn:hover {
    color: black;
    background-color: white;
}


.links {
    color: white;
}

.nav-group {
    width: 100%;
}

.links a {
    margin-left: 4px;
}

.header-label {
    text-align: left;
    padding-left: 30px;
    font-size: 16px;
    font-weight: bold;
    display: inline-block;
    padding-top: 10px;
    padding-right: 10px;
}

.connexion-label a {
    color: #FFFFFF;
}

.connexion-label a:hover {
    color: #FFC312;
    text-decoration: none;
}

.menu-style {
    margin-top: 55px;
    background: #d04c40 !important;
    position: absolute;
}

.content {
    padding: 0;
    overflow: scroll;
    overflow-x: hidden;
    min-height: 100%
    /*-webkit-overflow-scrolling: touch;*/
}

.required:before {
    content: "*";
    color: red;
}

.btn-flat {
    background-color: purple;
    color: white;
}

.scrollableX {
    overflow: auto
}

.img-right img {
    float: left;
    margin-right: 20px;
    vertical-align: middle;
}

.vertical {
    vertical-align: center;
    height: 100%
}

body {
    overflow: hidden;
}

.h100 {
    height: 100% !important;
    overflow: auto;
}

.h250 {
    height: 100% !important;
    overflow: auto;
}

.shadow {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.ad {
    border-bottom: 1px solid #707070;
    margin-left: 20px;
    margin-bottom: 20px;
    margin-top: 10px;
}

.greyBar {
    border-top: 1px solid #000000;
    height: 40px;
    vertical-align: middle;
    background-color: #E9E8E8;
    padding-left: 30px;
    margin-bottom: 20px;
    margin-top: 20px;
}

.editZone {
    background-color: #f7f8f8;
    margin-left: 20px;
    border-bottom: 20px;
}
.cancelButton {
    border: 1px solid black;
    border-radius: 5px;
    width: 65px;
}

.loweCase {
    text-transform: unset !important;
}
.DayPicker-Months {
   font-size: 12px;
}
#tag-list {
    display: inline;
    list-style-type: none;
    white-space: nowrap;
    width: 100%;
    margin-left: 10px;
}