@font-face {
    font-family: 'Bold';
    src: local('Bold'), url('../font/OpenSans-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'Regular';
    src: local('Regular'), url('../font/OpenSans-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Italic';
    src: local('Italic'), url('../font/OpenSans-Italic.ttf') format('truetype');
}

@font-face {
    font-family: 'Light';
    src: local('Light'), url('../font/OpenSans-Light.ttf') format('truetype');
}

@font-face {
    font-family: 'SemiBold';
    src: local('SemiBold'), url('../font/OpenSans-SemiBold.ttf') format('truetype');
}

@font-face {
    font-family: 'ExtraBold';
    src: local('ExtraBold'), url('../font/OpenSans-ExtraBold.ttf') format('truetype');
}

body {
    font-family: 'Regular';
    font-size: medium;
    background-color: #F5F5F5;
    height: 100%;;
}
